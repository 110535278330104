import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'explorer',
      component: () => import('@/views/PublicExplorer.vue'),
    },
    {
      path: '/log/:log',
      name: 'log',
      component: () => import('@/views/PublicExplorer.vue'),
    },
    {
      path: '/my-explorer',
      name: 'my-explorer',
      component: () => import('@/views/PrivateExplorer.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/AdminExplorer.vue')
    },
    {
      path: '/map',
      name: 'map',
      component: () => import('@/views/Map.vue'),
    },
    {
      path: '/decrypt',
      name: 'decrypt',
      component: () => import('@/views/DecryptImage.vue')
    },
    {
      path: '/scanner',
      name: 'scanner',
      component: () => import('@/views/Scanner.vue')
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

export default router
